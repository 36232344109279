@import '../../../../../styles/customMediaQueries.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--colorFail);
}

.arrayError {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
  color: var(--colorFail);
  width: 100%;
  clear: both;

  margin-top: 6px;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    margin-bottom: 10px;
  }
}

.row {
  display: flex;
  justify-content: space-between;

  gap: 24px;

  & > * {
    flex-grow: 1;
  }
}

.formMargins {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.note {
  composes: formMargins;

  color: var(--colorGrey600);
  margin-top: -24px;
}

.description {
  composes: formMargins;
  flex-shrink: 0;
}

.listingTypeSelect {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.selectedLabel {
  composes: label from global;
}
.selectedValue {
  margin-top: 0;
  margin-bottom: 6px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    padding: 2px 0 6px 0;
  }
}

.unitTypeHidden {
  display: none;
}

.inputMethod {
  margin-top: 12px;
}

.dimrFields {
  margin-top: 0;
  border: 1px solid var(--colorBorder);
  padding: 12px;
  border-radius: var(--borderRadiusInput);
  overflow-y: auto;
  max-height: 600px;
  background-color: var(--colorWhite);

  /* Show shadows to indicate that element can be scrolled */
  background:
  /* Shadow covers */ linear-gradient(white 30%, rgba(255, 255, 255, 0)),
    linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
    /* Shadows */ radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
    radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
  background:
  /* Shadow covers */ linear-gradient(white 30%, rgba(255, 255, 255, 0)),
    linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
    /* Shadows */ radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
    radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;

  /* Opera doesn't support this in the shorthand */
  background-attachment: local, local, scroll, scroll;

  & > li {
    &:not(:last-of-type) {
      margin-bottom: 12px;
      border-bottom: 1px solid var(--colorBorder);
      padding-bottom: 12px;
    }
  }
}

.dimrField {
  & > div {
    font-size: 14px;
    font-weight: var(--fontWeightSemiBold);
  }

  & > span {
    font-weight: var(--fontWeightBold);
  }
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}

.tdrImages {
  composes: formMargins;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;

  & img {
    background: var(--colorGrey100);
    border-radius: var(--borderRadiusInput);
  }
}

.schemaImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.conditionNote {
  color: var(--colorGrey300);
}
